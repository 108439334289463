<template>
  <div id="created" class="screen visibility-hidden faded-show">
    <div class="middle">
      <div class="middle-content">
        <Lottie name="win" :loop="false" :style="{ width: '120px', height: '120px', position: 'relative' }" class="screen-lottie" />

        <div class="screen-title">Congratulations</div>

        <div class="screen-text mb-10 mt-20">
          Your TON wallet has just been created.<br />
          Only you control it.
        </div>

        <div>
          <button @click="router.push('/backup')" id="createdContinueButton" class="btn-blue screen-btn mt-18 mb-20">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from '@/logic/functions'
const router = useRouter()
</script>